/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/* 追加のボタン配色 global.scssとvariables.sccsが一対*/
.ion-color-deeppink {
  --ion-color-base: var(--ion-color-deeppink);
  --ion-color-base-rgb: var(--ion-color-deeppink-rgb);
  --ion-color-contrast: var(--ion-color-deeppink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-deeppink-contrast-rgb);
  --ion-color-shade: var(--ion-color-deeppink-shade);
  --ion-color-tint: var(--ion-color-deeppink-tint);
}

.ion-color-pink {
  --ion-color-base: var(--ion-color-pink);
  --ion-color-base-rgb: var(--ion-color-pink-rgb);
  --ion-color-contrast: var(--ion-color-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink-shade);
  --ion-color-tint: var(--ion-color-pink-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

.ion-color-lightblue {
  --ion-color-base: var(--ion-color-lightblue);
  --ion-color-base-rgb: var(--ion-color-lightblue-rgb);
  --ion-color-contrast: var(--ion-color-lightblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightblue-shade);
  --ion-color-tint: var(--ion-color-lightblue-tint);
}

/* 追加のボタン配色　end*/

//　アラートのサイズ変更 alert size change
.alert-wrapper {
  //background: #490000;
  max-width: none!important;
  width:400px!important;
  //height:00px!important;
}


ng2-flatpickr input{
  min-width: 240px;
}


p{
  // color: rgb(77, 77, 77);
  // overflow: hidden;         // ...表示用
  // text-overflow: ellipsis;
  // white-space: nowrap;
  word-break: normal;
}

// modalの巨大化
.modal-fullscreen {
  .modal-wrapper {
      position: absolute;
      top: 5%;
      left: 5%;
      display: block;
      width: 90%;
      height: 90%;
  }
}
.ios .modal-fullscreen {
  .modal-wrapper {
      margin-top: 26px;
  }
}
// modalのフルスクリーン化　end